.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 5s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
  /* text-decoration: none */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.content-wrap {
  flex: 1;
}

.centered {
  background-color: #eee;
  position: fixed;
  width: 40%;
  text-align: center;
  top: 25%;
  left: 50%;
  padding: 20px;
  transform: translate(-50%, 0);
}

.inputRounded .MuiOutlinedInput-root {
  border-radius: 20px;
  /* font-size: 7px; */
}

#loader{
  position:fixed;
  top:0;
  left:0;
  width: auto;
  height: auto;
  background-color: lightslategrey;
}

.row {
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 24px;
}

.row:nth-child(1n) {
  background: #7b7;
}

.row:nth-child(2n) {
  background: #7c7;
}

.container {
  height: calc(100vh - 20px);
}
